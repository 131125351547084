import React from 'react';
import { Link } from 'react-router-dom';

const CloudSolutions = () => {
  document.body.style.backgroundColor =''
  return (
    <div className="container my-5">
      <h1 className="text-center mb-4">OMR Technology</h1>
      <p className="text-center mb-5">
      Our OMR Solution is an exceptional that has been used & tested from years to accumulate data out of OMR Bubble sheets, survey forms, assessments, evaluations. We guarantees 100% accuracy is an economical & best solution for reading thousands of OMR bubble Sheets within minutes. It has come up eliminating the solely dependencies over paper & scanner. Use your own office printer, plain paper sheets or any scanner (Flatbed/ADF/MFP), our solution will read it all with a reckless speed & will get the data analysis on forefront. Our solution has a user friendly software that doesn't constraint you to create templates using excel/word or any other designing software instead allows you to design professional OMR Sheets using its in-built designing tools.
      </p>
      <img style={{display: 'block', margin: 'auto'}} src="Images/omr.jpg" alt="omr"/>
      <div className="text-center my-5">
        <h3>Why Choose Star Solutions?</h3>
        <ul className="list-unstyled">
          <li>Customized Solutions tailored to your business</li>
          <li>Scalability to grow with your needs</li>
          <li>24/7 Support for uninterrupted service</li>
          <li>Security-Focused with advanced data protection</li>
        </ul>
        <Link to="/contact"><button className="btn btn-primary mt-3">Contact Us Today</button></Link>
      </div>
    </div>
  );
};

export default CloudSolutions;
